import React, { useMemo } from "react"
import HeaderV2 from "../headerv2/headerv2"
import HeroNoImage from "../../microcomponents/heronoimage/heronoimage"
import LeftRightImageText from "../../microcomponents/leftrightimagetext/leftrightimagetext"
import Image from "../../components/image-component/image-new"
import TopPredictions from "../../microcomponents/toppredictions/toppredictions"
import ClientResults from "../../microcomponents/clientresults/clientresults"
import Container from "../../microcomponents/container/container"
import Slider from "react-slick"
import HeadSubhead from "../../microcomponents/headsubhead/headsubhead"
import { teamphotos } from "../../utilities/teamphotos"
import { ourvalues } from "../../utilities/ourvalues"
import ReactHTMLParser from "react-html-parser"
import "./aboutusv2.scss"
import { Spiralyze } from "../../microcomponents/spiralyze/spiralyze"
export default function AboutUsV2() {
  const settings = useMemo(() => {
    return {
      dots: false,
      infinite: true,
      autoplay: false,
      variableWidth: true,
      arrows: false,
      slidesToShow: 5,
      centerMode: true,
      centerPadding: "0px",
      responsive: [
        {
          breakpoint: 2000,
          settings: {
            slidesToShow: 4,
            centerPadding: "120px",
          },
        },
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 3,
            centerPadding: "4%",
          },
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
            centerPadding: "4%",
          },
        },
        {
          breakpoint: 991,
          settings: {
            centerMode: true,
            centerPadding: "8%",
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            centerPadding: "5%",
            slidesToShow: 1,
          },
        },
      ],
    }
  }, [])

  const settings2 = useMemo(() => {
    return {
      dots: false,
      infinite: true,
      autoplay: false,
      arrows: false,
      slidesToShow: 4,
      centerMode: true,
      centerPadding: "0px",
      responsive: [
        {
          breakpoint: 2000,
          settings: {
            slidesToShow: 4,
            centerPadding: "120px",
          },
        },
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 2,
            centerPadding: "21%",
          },
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 1,
            centerPadding: "32%",
          },
        },
        {
          breakpoint: 991,
          settings: {
            centerPadding: "18%",
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            centerPadding: "15%",
            slidesToShow: 1,
          },
        },
      ],
    }
  }, [])

  return (
    <div>
      <HeaderV2 version="v2" />
      <HeroNoImage
        className="honey greeneclipse"
        heading="About Spiralyze"
        breadcrumb={[
          {
            name: "Home",
            url: "/",
          },
          {
            name: "About Spiralyze",
            url: null,
          },
        ]}
        description="<p>Increasing conversion rates using predictive CRO.</p>"
        component={null}
      />
      <LeftRightImageText
        mainHeading="Get fast and <br class='hidetablet hideipad hidemobile'/> consistent results <br class='hidemobile'/> with predictive CRO."
        subHeading=""
        sectionDescription="<p>Spiralyze harvests data from the <br class='hidetablet hideipad'/> 78,000 websites that <br class='hidedesktop hidemobile'/> actively A/B test <br class='hidetablet hideipad'/> and uses that data to predict what <br class='hidetablet hideipad hidemobile'/> will <br class='hidedesktop'/> work for our clients. This data-<br class='hidetablet hideipad hidemobile'/>centric approach helps <br class='hidedesktop hidemobile'/> clients benefit from <br class='hidedesktop hidetablet hideipad'/> the global wisdom of all A/B  <br class='hidetablet hideipad hidemobile'/> testing to <br class='hidedesktop'/> get faster and more  <br class='hidetablet hideipad hidemobile'/> consistent wins <br class='hidedesktop hidetablet hideipad'/> than legacy CRO.</p>"
        imageFolder="how_it_works"
        sectionImage="howitworksimage1.webp"
        innerClasses="absolute-image-section"
        wrapperClasses="pd-56-tablet-top"
        id="clustering"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              fallBackImage={`image_68.webp`}
              alt={`Clustering Tests`}
              imgName={[
                {
                  imageName: "image_68.webp",
                  minBreakpoint: "1025px",
                },
                {
                  imageName: "image_67.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "image_64.webp",
                  minBreakpoint: "0px",
                },
              ]}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </>
        }
      ></LeftRightImageText>
      <LeftRightImageText
        mainHeading="Full-service delivery <br class='hidetablet hideipad'/> model <br class='hidedesktop hidemobile'/> helps run <br class='hidetablet hideipad hidemobile'/> more tests."
        subHeading=""
        sectionDescription="<p>We take care of all the details that <br class='hidetablet hideipad'/> slow down testing <br class='hidedesktop hidemobile'/> programs.<br class='hidetablet hideipad'/> Spiralyze provides customers with a dedicated <br class='hidedesktop hidemobile'/> team that handles  <br class='hidetablet hideipad'/> everything, from design through <br class='hidedesktop'/> development. We supply all the <br class='hidetablet hideipad'/> needed software. We even <br class='hidedesktop hidemobile'/> hard-code winners. So our clients get a high-<br class='hidetablet hideipad'/>velocity <br class='hidedesktop hidemobile'/> pipeline of quality tests, more wins, and more revenue <br class='hidedesktop hidemobile'/> growth.</p>"
        imageFolder="how_it_works"
        sectionImage="howitworksimage1.webp"
        innerClasses="reverse absolute-image-section"
        wrapperClasses="gray-bg pd-56-tablet-top"
        id="clustering"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              fallBackImage={`imagery-desktop-05_2.webp`}
              alt={`Clustering Tests`}
              imgName={[
                {
                  imageName: "imagery-desktop-05_2.webp",
                  minBreakpoint: "1025px",
                },
                {
                  imageName: "image_66.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "image_65.webp",
                  minBreakpoint: "0px",
                },
              ]}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </>
        }
      ></LeftRightImageText>

      <div className="pd-112-top" id="clustering">
        <Container>
          <HeadSubhead
            version={`v2`}
            heading={`<span>Meet <br class='hidedesktop hidetablet hideipad'/> our team</span>`}
            subheading={`170 people. 16 countries. Headquartered in Atlanta, Georgia.<br class='hidemobile'/> Alumni from McKinsey, Salesforce, and Facebook.<br class='hidemobile'/> Obsessively focused on conversion.`}
          ></HeadSubhead>
        </Container>
        <div className="full-vw-section-wrapper image-continous-slider">
          <Slider
            className="multiple-tile-image-slider teamslider"
            {...settings}
          >
            {teamphotos?.slice(0, 5)?.map((slide, index) => (
              <div className="team-tile" key={index}>
                <Image
                  sourceFolder={"spiralyze2024website"}
                  lazyLoad={"true"}
                  style={{ width: "100%" }}
                  fallBackImage={`slider-item-desktop.webp`}
                  imgName={slide}
                  cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
                />
              </div>
            ))}
          </Slider>
          <Slider
            className="multiple-tile-image-slider teamslider"
            {...settings2}
          >
            {teamphotos?.slice(5, 40)?.map((slide, index) => (
              <div className="team-tile" key={index}>
                <Image
                  sourceFolder={"spiralyze2024website"}
                  lazyLoad={"true"}
                  style={{ width: "100%" }}
                  fallBackImage={`slider-item-desktop.webp`}
                  imgName={slide}
                  cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <Container className="all-inclusive-wrapper gray-bg pd-112">
        <HeadSubhead
          version={`v2`}
          heading={`<span>Our Values</span>`}
          subheading={``}
        ></HeadSubhead>
        <div className="our-values-inner">
          {ourvalues.map((value, index) => {
            return (
              <div className="our-values-card" key={index}>
                <Image
                  sourceFolder={"spiralyze2024website"}
                  lazyLoad={"true"}
                  style={{ width: "100%" }}
                  fallBackImage={`slider-item-desktop.webp`}
                  imgName={value.imgName}
                  cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
                />
                <div className="our-values-information">
                  <h3>{ReactHTMLParser(value.title)}</h3>
                  <p>{ReactHTMLParser(value.desc)}</p>
                </div>
              </div>
            )
          })}
        </div>
        <Spiralyze />
      </Container>

      <TopPredictions version="v2" />
      <ClientResults version="v2" />
    </div>
  )
}
