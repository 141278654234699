import * as React from "react"
import Layout from "../components/layout2023"
import SEO from "../components/seo2023"
import FooterV2 from "../componentsv2/footerv2/footerv2"
import AboutUsV2 from "../componentsv2/aboutusv2/aboutusv2"

const AboutUs = () => {
  return (
    <Layout nav={"hidden"} parentClass={`newtheme2024`}>
      <SEO title="About Us" />
      <AboutUsV2 />
      <FooterV2 version="v2" />
    </Layout>
  )
}
export default AboutUs
