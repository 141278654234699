export const teamphotos = [
  // "team-photos-01_1.webp",
  // "team-photos-02_1.webp",
  // "team-photos-03_1.webp",
  // "team-photos-04_1.webp",
  // "team-photos-05_1.webp",

  // "team-photos-06_1.webp",
  // "team-photos-07_1.webp",
  // "team-photos-08_1.webp",
  // "team-photos-09_1.webp",
  "frame_1171275839.webp",
  "frame_1171275850.webp",
  "frame_1171275849.webp",
  "frame_1171275846.webp",
  "frame_1171275841.webp",
  "frame_1171275837.webp",
  "frame_1171275840.webp",
  "frame_1171275851.webp",
  "frame_1171275848.webp",
  "frame_1171275845.webp",
  "frame_1171275835.webp",
]
