import React from "react"
import { SpzLogoColorFull } from "../icons"

export const Spiralyze = () => {
  return (
    <div className="spz-verb-outer">
      <div className="spz-verb-inner">
        <div>
          <SpzLogoColorFull />
        </div>
        <div className="spz-verb-text">
          <p>
            <b>[spī·rəl·īz ] verb</b>
            <br />
            to create a continuous and dramatic increase.
          </p>
        </div>
      </div>
    </div>
  )
}
