import React from "react"
import ReactHtmlParser from "react-html-parser"

import "./leftrightimagetext.scss"
import Container from "../container/container"
import MainHeading from "../mainheading/mainheading"
export default function LeftRightImageText({
  children,
  subHeading = "",
  mainHeading = "",
  wrapperClasses = "",
  innerClasses = "",
  sectionDescription = "",
  imageComponent = null,
  backgroundImage = "",
  id = "",
}) {
  return (
    <Container id={id} className={`imagetext-section ${wrapperClasses}`}>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
        className={`imagetext-section-inner ${innerClasses}`}
      >
        <div className="imagetext-section-left text">
          <div className="wrap-text">
            <MainHeading className="main-heading" subHeading={subHeading}>
              {mainHeading}
            </MainHeading>
            {ReactHtmlParser(sectionDescription)}
            {children}
          </div>
        </div>
        <div className="imagetext-section-right image">
          <div className="wrap-image">{imageComponent}</div>
        </div>
      </div>
    </Container>
  )
}
