export const ourvalues = [
  {
    title: "Use data to run <br class='hidedesktop'/> proven winners",
    desc:
      "Have the most data on what <br class='hidedesktop'/> works. Use that data to run <br class='hidetablet hideipad hidemobile'/> tests <br class='hidedesktop'/> that we know will win.",
    imgName: [
      {
        imageName: "frame_1000003442.webp",
        minBreakpoint: "1025px",
      },
      {
        imageName: "frame_1000003447.webp",
        minBreakpoint: "0px",
      },
    ],
  },
  {
    title: "Delight through <br class='hidedesktop'/> experimentation",
    desc:
      "Run tests that clients want <br class='hidetablet hideipad hidemobile'/> to <br class='hidedesktop'/> win. Make testing the <br class='hidetablet hideipad hidemobile'/> vehicle that <br class='hidedesktop'/> leads to an epic <br class='hidetablet hideipad hidemobile'/> site.",
    imgName: [
      {
        imageName: "frame_1000003451.webp",
        minBreakpoint: "1025px",
      },
      {
        imageName: "frame_1000003450.webp",
        minBreakpoint: "0px",
      },
    ],
  },
  {
    title: "Be short term <br class='hidedesktop'/> annoying",
    desc:
      "Push the team, client, and <br/> yourself, to achieve the mission. <br class='hidedesktop'/> Even when unpopular.",
    imgName: [
      {
        imageName: "frame_1000003449.webp",
        minBreakpoint: "1025px",
      },
      {
        imageName: "frame_1000003446.webp",
        minBreakpoint: "0px",
      },
    ],
  },
  {
    title: "Get <br class='hidemobile'/> results",
    desc:
      "Above all else, deliver wins. <br class='hidedesktop'/>  Measurable, long-term, and <br class='hidedesktop'/>  impactful business results.",
    // desc:
    //   "Above all delivery clients <br class='hidetablet hideipad hidemobile'/> results.<br class='hidedesktop'/>  Measurable, long-<br class='hidetablet hideipad hidemobile'/>term, and large <br class='hidedesktop'/> results.",
    imgName: [
      {
        imageName: "frame_1171274901.webp",
        minBreakpoint: "1025px",
      },
      {
        imageName: "frame_1000003452.webp",
        minBreakpoint: "0px",
      },
    ],
  },
]
